import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Template from "@/views/Template";
import ProductPresentation from "@/components/ProductPresentation";
import ProductAdvantages from "@/components/ProductAdvantages";
import AboutProduct from "@/components/AboutProduct";
import Typography from "@/components/Typography";
import InvestTime from "@/containers/InvestTime";
import PlansBox from "@/components/PlansBox";
export const _frontmatter = {
  "title": "Processos Sociétarios",
  "path": "/processos-societarios",
  "date": "2019-01-29T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Template;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ProductPresentation advantages={["Consultoria para modelagem do seu negócio", "Abertura de Empresa", "Abertura de Filial", "Alteração Contratual", "Extinção Empresarial"]} sideTitle="PROCESSOS SOCIETÁRIOS" contactLink="https://wa.me/5581994019577" knowMoreLink="https://www.instagram.com/leancontabilidade" description="O direito societário abrange desde a constituição de uma empresa ao relacionamento entre os sócios. Por isso, vamos direto ao ponto e identificamos sua necessidade, otimizando o seu tempo e a burocracia na hora de realizar os processos societários." title={() => <>
      Nós vamos{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        adequar sua empresa
      </Typography>{" "}
      e lhe auxiliar, conforme a legislação vigente, sobre a estrutura
      societária adequada.
    </>} mdxType="ProductPresentation" />{" "}
    <InvestTime mdxType="InvestTime">
  <Typography align="center" color="primary" variant="h1" mdxType="Typography">
    Invista mais tempo no seu negócio{" "}
    <Typography as="span" color="primary" variant="h1" weight={300} mdxType="Typography">
      e
    </Typography>{" "}
    deixe a burocracia com a gente.{" "}
    <Typography as="span" color="primary" variant="h1" weight={300} mdxType="Typography">
      Faça já sua
    </Typography>{" "}
    contabilidade online.
  </Typography>
    </InvestTime>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      